import { FC } from 'react';
import { cx } from '@emotion/css';

import { Button } from 'components';
import { ReferralCard, TokenAmount } from 'modules/shared';
import { numbers } from 'utils';
import { TTableColumns } from 'types';

import { AprTooltip } from '../aprTooltip';

import { IReferralFarmsFarmRow } from '../../types';
import { EReferralFarmsPageType } from '../store/types';

import styles from './styles.module.css';

interface IFarmsTableBody {
  farms: IReferralFarmsFarmRow[];
  columns: TTableColumns;
  farmCTA: {
    label: string,
    handleClick: (farm: IReferralFarmsFarmRow) => void,
  },
  pageVariant?: EReferralFarmsPageType
}

export const FarmsTableBody: FC<IFarmsTableBody> = ({
  farms,
  columns,
  farmCTA,
  pageVariant = EReferralFarmsPageType.referralFarming,
}) => (
  <>
    {farms.map((farm, i) => {
      const {
        referToken, rewardsTokens, APRs, estimatedDailyRewards,
      } = farm;
      const key = referToken.referredTokenDefn;
      const noRewards = rewardsTokens.some(({ totalRewards }) => totalRewards.amount === 0);

      return (
        <tr
          key={key}
          className={cx(styles.row, {
            [styles.rowDisabled]: noRewards,
          })}
        >
          <td
            className="flex"
            data-label={columns[0].header}
          >
            <span className={styles.rowIndex}>{i + 1}</span>
            <ReferralCard
              token={referToken}
              tokenIconSize="md"
            />
          </td>
          <td data-label={columns[1].header}>
            <div className={styles.rowText}>
              {rewardsTokens
                .map(({
                  dailyRewards, tokenSymbol,
                }) => (
                  <TokenAmount
                    key={`${key}-${dailyRewards.amount} ${tokenSymbol}`}
                    amount={numbers.getShorterNumber(dailyRewards.amount)}
                    tokenSymbol={tokenSymbol}
                    rewardsLockTime={dailyRewards.rewardsLockTime}
                  />
                ))}
            </div>
          </td>
          <td data-label={columns[2].header}>
            <div className={styles.rowText}>
              {rewardsTokens
                .map(({
                  totalRewards, tokenSymbol,
                }) => (
                  <TokenAmount
                    key={`${key}-${totalRewards.amount} ${tokenSymbol}`}
                    amount={numbers.getShorterNumber(totalRewards.amount)}
                    tokenSymbol={tokenSymbol}
                  />
                ))}
            </div>
          </td>
          <td
            data-label={columns[3].header}
            key="estApr"
          >
            <div className={styles.cell}>
              <div className={styles.rowText}>
                {APRs.map((apr) => (
                  <div
                    className={styles.apr}
                    key={apr}
                  >
                    {apr}
                  </div>
                ))}
              </div>
              <div className={styles.rowText}>
                {estimatedDailyRewards.map((reward, idx) => (
                  <AprTooltip
                    key={reward}
                    estimatedDailyReward={reward}
                    referredTokenSymbol={referToken.symbol}
                    rewardTokenSymbol={rewardsTokens[idx].tokenSymbol}
                    pageType={pageVariant}
                  />
                ))}
              </div>
            </div>
          </td>
          <td data-label={columns[4].header}>
            <div className={styles.rowText}>
              <Button
                size="md"
                disabled={noRewards}
                onClick={() => farmCTA.handleClick(farm)}
              >
                {farmCTA.label}
              </Button>
            </div>
          </td>
        </tr>
      );
    })}
  </>
);
